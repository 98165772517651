var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("new-employee-registration")) + " - AMANAH WARISAN BERHAD ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center border-bottom"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('h4', {
    staticClass: "title text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("staff-awaris")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('img', {
    staticClass: "avatar avatar-large rounded-circle shadow d-block mx-auto",
    attrs: {
      "src": "images/client/05.jpg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_c('a', {
    staticClass: "btn btn-primary mt-2",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(_vm._s(_vm.$t("change-picture")))]), _c('a', {
    staticClass: "btn btn-outline-primary mt-2 ml-2",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(_vm._s(_vm.$t("remove")))])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("new-ic")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No kad pengenalan",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No kad pengenalan",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("male")))]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("female")))])])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("staff-code")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Kod Kakitangan",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("staff-no")))]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Pekerja",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Alamat",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Poskod",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Bandar",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Negeri",
      "name": "",
      "required": ""
    }
  })])])])]), _c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mobile-no")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Telefon Bimbit",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("office-no")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Telefon Pejabat",
      "name": "",
      "required": ""
    }
  })])])])])]), _c('div', {
    staticClass: "row align-items-center mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("user-status")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("user-role")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("* (" + _vm._s(_vm.$t("cant-be-empty")) + ")")])]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Super Admin")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("staff")))]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accountant")))])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Email",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("username")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Username",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("password")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Kata Laluan",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("confirm-password")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Sahkan Kata Laluan",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    staticStyle: {
      "background": "#eee",
      "padding": "10px"
    }
  }, [_c('div', {
    staticClass: "inner"
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('label', [_vm._v(_vm._s(_vm.$t("min-password-req")) + ":")]), _c('p', {
    staticClass: "small"
  }, [_vm._v(" 1. " + _vm._s(_vm.$t("min-atleast-8-char")) + ". "), _c('br'), _vm._v("2. " + _vm._s(_vm.$t("must-be-uppercase-lowercase-letters")) + ". "), _c('br'), _vm._v("3. " + _vm._s(_vm.$t("must-be-a-number")) + ". "), _c('br'), _vm._v("4. " + _vm._s(_vm.$t("symbols-are-allowed")) + ". ")])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative text-right"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": "/page-blog-grid"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _c('i', {
    staticClass: "mdi mdi-chevron-right"
  })])], 1)])])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }